import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../../variants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import "./index.css";

const Newsletter = () => {
  const form = useRef();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Récupérer les adresses e-mail déjà abonnées depuis le localStorage
    const subscribedEmails =
      JSON.parse(localStorage.getItem("subscribedEmails")) || [];
    setIsSubscribed(subscribedEmails.includes(email));
  }, [email]);

  const sendEmail = (e) => {
    e.preventDefault();

    // Vérifier si l'utilisateur est déjà abonné
    if (isSubscribed) {
      toast.info("Vous êtes déjà abonné !");
      return;
    }

    emailjs
      .sendForm(
        "service_b0qb4h3",
        "template_k042n0j",
        form.current,
        "pgp1hpfpOmLlkG7rz"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubscribed(true); // Mettre à jour l'état de l'abonnement
          toast.success("Merci pour votre abonnement à notre newsletter !", {
            autoClose: 3000,
          });
          setEmail(""); // Réinitialiser le champ de l'email après l'abonnement

          // Enregistrer l'adresse e-mail abonnée dans le localStorage
          const subscribedEmails =
            JSON.parse(localStorage.getItem("subscribedEmails")) || [];
          localStorage.setItem(
            "subscribedEmails",
            JSON.stringify([...subscribedEmails, email])
          );
        },
        (error) => {
          console.log(error.text);
          toast.error("Une erreur est survenue, veuillez réessayer.");
        }
      );
  };

  return (
    <div className="newsletter">
      <div className="form">
        <h2 className="title">S'ABONNER À NOTRE NEWSLETTER</h2>
        <div className="newsletter-border"></div>
        <motion.div
          variants={fadeIn("right", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.3 }}
        >
          <form ref={form} onSubmit={sendEmail}>
            {/* Email */}
            <input
              className="newsletter-input"
              type="email"
              name="user_email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {/* Submit */}
            <button type="submit" value="Subscribe" className="newsletter-btn">
              S'ABONNER
            </button>
          </form>
         
        </motion.div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Newsletter;
