import React, { useState, useEffect } from "react";
// import New1 from "../../assets/ecpyrose.jpeg";
// eslint-disable-next-line
import New2 from "../../assets/saturnesurmer.jpeg";
import New3 from "../../assets/bilanpositif.jpeg";
import New4 from "../../assets/jekyll.jpg";
import New5 from "../../assets/ecpyrose.jpg";
import "./News.css";
import { motion } from "framer-motion";

const News2 = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500); // Réglez la durée du clignotement en millisecondes

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className="max-sm:pt-[30px] max-sm:h-[100%] md:h-[100%] h-[100%] py-6 flex items-center justify-center bg-black text-white max-sm:flex-col overflow-hidden"
      id="news"
    >
      <div className="flex flex-col">
        <h2 className="text-center font-oswald text-[2rem] mb-3 pt-5 max-sm:text-[30px]">
          ACTUALITÉS
        </h2>
        <div className="border border-solid border-1 border-fe955a bg-fe955a max-w-[150px] mx-auto"></div>

        <div className="flex justify-center mt-2">
          <div className="flex flex-col gap-y-[100px]">
          
            {/* NEW 1 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New5}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <motion.h1
                    className="text-[40px] font-openSans max-sm:text-[30px]"
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                        transition: { duration: 0.5, yoyo: Infinity },
                      },
                    }}
                    initial="hidden"
                    animate={isVisible ? "show" : "hidden"}
                    viewport={{ once: false, amount: 0.3 }}
                  >
                    ALBUM ÉVÈNEMENT !
                  </motion.h1>
                  <div className="max-w-[600px]  max-lg:max-w-[300px]">
                    <p className="font-openSans text-justify text-[16px] max-sm:text-[16px] max-sm:text-center font-bold text-white opacity-90 mb-3 leading-6">
                      Disponible le 02 mai 2024 sur toutes les plateformes.
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      « Ecpyrose » est un album baroque, aux confluences de
                      l’électro-pop et de la musique symphonique, porté par la
                      voix chaude de Florent Richard, avec l’incendiaire vérité
                      de l’intime en toile de fond. Huit chansons ardentes et
                      poétiques, fragments d’une vie d’homme où les derniers
                      possibles se rêvent, se consomment puis se consument.
                      Jusqu’à l’ecpyrose.
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Les titres de ce nouvel opus ont été composés par Florent
                      Richard et co-arrangés avec Yannick Soccal, sur des textes
                      de Vincent Fernandel.
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://wiseband.lnk.to/Florent-Richard-Ecpyrose-album"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 3 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5m max-sm:gap-3 gap-9 justify-start items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New4}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <div className="max-w-[600px] max-sm:max-w-80 max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-[16px] max-sm:text-center text-justify font-bold text-white opacity-90 mb-3">
                      « Jekyll Island »
                    </p>
                    <p className="text-[16px] text-justify font-openSans">
                      Le nouveau single de Florent Richard
                    </p>
                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://wiseband.lnk.to/Florent-Richard-Jekyll-Island-Radio-edit"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                        <a
                          href="https://www.youtube.com/watch?v=McOs7ABX2F8&ab_channel=FlorentRichard"
                          className="ml-5"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Voir le clip
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* NEW  */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-5 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New3}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <div className="max-w-[600px] max-sm:max-w-80 max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-sm max-sm:text-center text-justify font-bold text-white opacity-90 mb-3 leading-6">
                      FRANCK FERNANDEL - BILAN POSITIF (1983)
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Découvrez pour la première fois en digital cet album
                      incontournable de Franck Fernandel. Dix chansons
                      entièrement remasterisées, dont "L'amour interdit", tube
                      des années 80.
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="news-link max-sm:mx-auto">
                        <a
                          href="https://fernandel.lnk.to/bilanpositif"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ÉCOUTER
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News2;
