import Artist1 from '../../assets/artistes/chantal1.jpeg'
import Artist2 from '../../assets/artistes/florentrichard.jpeg'
import Artist3 from '../../assets/artistes/jeanlucmorel.jpeg'
import Artist4 from '../../assets/artistes/franckfernandel.jpg'

export const artistsData = [

  {
    id: 1,
    img: Artist2,
    name: 'FLORENT RICHARD',
    title: 'Interprète, auteur, compositeur, arrangeur',
    description: `Fin technicien de la musique et subtil défenseur du décalage, Florent Richard se forge une carrière où rigueur et fantaisie font bon ménage. Violoncelliste et bassiste de formation, également pianiste, il se perfectionne en matière d’arrangement à l’American School of Modern Music. Son premier album L’art et la manière (Warner), paru en 2006, reçoit l’accueil unanime de la presse spécialisée et du grand public. Un succès entériné par Un jour comme ça, sorti l’année suivante. Les ballades mélancoliques de Florent deviennent sa signature : l’allégresse bastringue s’entrechoque à la nervosité du jazz, son interprétation rocailleuse frôle l’ironie mais flirte volontiers avec la poésie. Il explore les multiples facettes de sa stylistique, de 3ème album (2013), en passant par Le grand blond et l’accordéoniste (2019), jusqu’à Initials SG (2021), album tout en flamboyance consacré à la revisitation de Gainsbourg, réalisé avec Yannick Soccal, saxophoniste de Véronique Sanson. En 2021, Vincent Fernandel devient le producteur de Florent et l’incite à prendre un virage dans sa carrière. Après Falling for, opus acoustique où l’artiste renoue avec la douceur éthérée de ses premières amours musicales, Florent Richard et Vincent Fernandel co-écrivent Ecpyrose, un album résolument abyssal dans lequel Florent révèle sa pleine maturité de musicien et d’interprète. Ecpyrose sort le 02 mai 2024 sur toutes les plateformes.`,
    linkSpotify: 'https://open.spotify.com/artist/6WTEK43c8WZe9VfxlhzG6q',
    linkDeezer: 'https://www.deezer.com/fr/artist/55734',
  },
  {
    id: 2,
    img: Artist1,
    name: 'CHANTAL DE MAGE',
    title: 'Chanteuse, autrice, compositrice, comédienne',
    linkSpotify: 'https://open.spotify.com/artist/1eG8Ul9A4j5GoulAFVk5lC',
    linkPopCore: 'https://chantaldemage-popcore7.webnode.fr/',
    description: 'Artiste pluridisciplinaire, Chantal de Mage partage sa carrière entre l’acting et la chanson. Elle écrit des pièces (Chap’ron règle son conte, Massacre à lèvres nerveuse, Elle est libre Lala…) qu’elle interprète dans des festivals renommés et joue au théâtre ainsi qu’au cinéma sous la direction de Coline Serreau, Blandine Lenoir ou encore John Adam Smith. Elle utilise également sa plume comme scalpel pour façonner un répertoire musical hybride entre chanson française traditionnelle et underground décomplexé, toujours en collaboration avec le compositeur et arrangeur Julien Rosa. Après Origines I, album tissé de poésie brute, arrive Pop’Core, nouvel opus coup de poing qui explore aussi bien le monde urbain que la viscéralité féminine, sur des mélodies à la fois planantes et enragées. Sortie prévue courant 2024. ',
  },
  {
    id: 3,
    img: Artist4,
    name: 'FRANCK FERNANDEL',
    title: 'Interprète, auteur, compositeur',
    description: 'Franck Fernandel entame sa carrière au début des années 60 en tournant un premier film aux côtés de son père, l’acteur Fernandel. Il est repéré par l’auteur M.G. Sauvajon qui lui offre le premier rôle de la pièce Bienheureuse Anaïs. Le succès est tel que la maison de disques Philips propose à Franck de chanter et le signe en exclusivité. En plein déferlement yéyé, il impose sa voix velours de crooner, enchaînant les tournées en France puis au Canada où il est premier au hit-parade avec Les yeux d’un ange, en 1968. Récompensé par l’accueil du public, il enregistre un grand nombre de disques dont certains remportent un franc succès, notamment L’amour interdit, chanson forte sur la problématique de l’adultère, qui se vend à 400 000 exemplaires dans les années 80. Parmi ses titres phares, citons aussi C’est comme ça Marseille, Bilan Positif, Les enfants c’est comme les oiseaux ou encore César, Fanny, Vincent et Fernand. Franck Fernandel devient également animateur radio sur Europe 1 puis RMC, et joue régulièrement au théâtre ainsi qu’à la télévision. Amoureux du Midi, il s’y plait tant qu’il retarde souvent ses rendez-vous avec le show-biz parisien, préférant chanter pour son public toujours fidèle au fil des années. Il s’éteint sous le soleil de Marseille en 2011, à l’âge de 75 ans.',
    description2: 'Vincent Fernandel Production réacquiert les droits de plusieurs titres de Franck Fernandel, aussi bien des succès que des inédits, pour les proposer en versions remasterisées sur toutes les plateformes.',
    linkSpotify: 'https://open.spotify.com/artist/5a25kaYV14giqkjOMfvWeP',
    linkDeezer: 'https://www.deezer.com/fr/artist/55577?utm_source=adwords&utm_campaign=acq_FR_sea-dsa_web_search_perf_tnb-directsub&utm_medium=search&utm_content=dsa_premium_tnb_dsa-allsite',
  },
  {
    id: 4,
    img: Artist3,
    name: 'JEAN-LUC MOREL',
    title: 'Parolier, auteur, éditeur musical',
    description: 'Jean-Luc Morel voit le jour dans le Nord de la France en 1950, dans une famille de commerçants où l’art occupe une place prépondérante. Le jeune enfant développe une sensibilité précoce pour la peinture, la littérature et la musique. Des chansons de Brel ou Aznavour aux écrits de Cioran ou Simenon, le sortilège des mots s’empare de Jean-Luc qui se met à écrire comme d’autres respirent. Son plaisir à manier le vers le conduit naturellement vers la poésie et surtout la chanson. Ses efforts et son talent en la matière attirent l’attention de son professeur de français qui convainc les parents de Jean-Luc à encourager la vocation de leur fils. À 16 ans, parrainé par Charles Trenet, il entre à la SACEM et en devient le plus jeune membre. Dès lors, il pose les premiers jalons d’une œuvre pléthorique qui, aujourd’hui, compte plus de cinq cents chansons interprétées par une centaine d’artistes parmi lesquels Dalida, Mireille Mathieu, Barbara, Nana Mouskouri, Les Frères Jacques, Graeme Allwright, Jean Rochefort, Renaud, Franck Fernandel. Demandé par les plus prestigieux labels (RCA, EMI, Barclay…), Jean-Luc embrasse tous les genres, de la chanson d’amour à la ritournelle populaire en passant par l’électro, s’inscrivant ainsi dans le paysage musical pendant plus de 50 années d’une carrière au cours de laquelle il écrit également des livres jeunesse et crée le célèbre dessin animé Les Babalous. En 2019, son travail de parolier est récompensé par la médaille d’honneur de la SACEM. Toujours en activité, Jean-Luc Morel vit désormais loin de Paris où il vécut et fit les beaux jours d’une certaine chanson française. En 2023, il confie la gestion de son œuvre à Vincent Fernandel Production',
    description3: '',
    link: 'https://www.discogs.com/fr/artist/1093263-Jean-Luc-Morel',
  },

]



export default artistsData