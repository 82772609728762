
export const ProductionData = [

  {
    id: 1,
    title: 'Un engagement professionnel et humain',
    subtitle: 'VFP propose des valeurs de management durable et qualitatif, dans le souci constant de donner le temps et l’écoute nécessaires à l’action créative. Le propre d’une entreprise à taille humaine. ',
    text: `« Ce qui m’intéresse chez un artiste, c’est l’alliance de la technicité et de la singularité, qu’il ait déjà une carrière ou qu’il en soit à ses débuts. L’important, c’est de lui offrir, quel que soit son parcours, les conditions adéquates pour potentialiser au maximum ses aptitudes à la création, tant au niveau de la production musicale, de l’écriture, de la direction artistique ou encore du management. VFP prend le parti d’octroyer le temps et le confort indispensables aux artistes pour qu’émergent des œuvres à la mesure de leur talent. »`
  },
  {
    id: 2,
    title: 'Une logique artistique',
    subtitle: 'Vincent Fernandel se donne pour objectif de faire émerger les nouveaux talents et de remettre la lumière sur quelques artistes un peu oubliés ou passés au travers des mailles du succès.',
    text: `« Mon travail se situe sur deux plans : développer des artistes d’aujourd’hui à l’univers alternatif, hybride et accompagner la création originale à tous les niveaux. Réhabiliter un certain patrimoine musical qui n’a pas eu sa chance. Je pars également à la recherche d’artistes dont les titres ne sont plus exploités mais qui gardent pourtant l’esprit, l’empreinte unique d’une époque, et qui méritent d’être remis à la disposition du public.»`
  },
  {
    id: 3,
    title: 'Les origines',
    subtitle: 'VFP propose des valeurs de management durable et qualitatif, dans le souci constant de donner le temps et l’écoute nécessaires à l’action créative.',
    text: 'Vincent Fernandel est né dans l’univers musical, notamment parce que son père, Franck Fernandel, était chanteur, musicien, compositeur et féru de jazz. Ainsi, Vincent baigne aussi bien dans le monde du music-hall que du spectacle vivant. Sensible à la qualité de l’écriture autant qu’à la richesse de la musicalité, Vincent est très attaché à la diversité : « Dans ma discothèque, on trouve un peu de tout : de la chanson française bien sûr, de la variété internationale, beaucoup de jazz, de la disco-funk, du rock progressif européen, et quelques ovnis inclassables qui témoignent de mon goût prononcé pour les choses hybrides, alternatives, bien que, dans l’absolu, je n’ai pas de genre de prédilection. Le producteur que je suis devenu ressemble au mélomane curieux que j’ai toujours été. »'
  }

]

export default ProductionData