import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Production from './production/home/Production'
import Mentions from './production/pages/mentions';
import Privacy from './production/pages/privacy';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exactly path='/' element={<Production />} />
          <Route path='/mentions' element={<Mentions />} />
          <Route path='/politiquedeconfidentialite' element={<Privacy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
