import React from 'react'
import ImgBanner from '../../assets/banner-pepites.jpg'
import { motion } from "framer-motion"
import { fadeIn } from "../../../variants"


import './Banner.css'
import '../../index.css'


const Banner = () => {
  return (
    <div className='banner'>
      <motion.h2
        variants={fadeIn("down", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.3 }}
        className='banner-title'>PÉPITES VINTAGE</motion.h2>
      <img src={ImgBanner} alt="banner" />
      <motion.p
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.3 }}>Dans sa démarche patrimoniale, et en partenariat avec Musiques et Solutions, Vincent Fernandel Production propose un catalogue de chansons (re)venues tout droit des années 70 et 80, pour la plupart introuvables depuis leur sortie, en versions remasterisées. Découvrez ces raretés vintage interprétées par Marine, Brooks, Kannelle, Delahaye Sisters, et bien d’autres artistes inclassables qui portent avec eux les sons et le souffle d’une douce nostalgie. Premiers titres de cette collection disponibles sur toutes les plateformes de streaming courant 2024.</motion.p>
    </div>
  )
}

export default Banner

