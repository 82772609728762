import IntroImg from "../../assets/header.jpg"

import 'react-awesome-button/dist/styles.css'


import "./HeroImgStyles.css"

const HeroImg = () => {
  return (
    <div className='hero' id="hero">
      <div className='mask'>
        <img className='intro-img' src={IntroImg} alt="IntroImg" />
      </div>
      <div className='content'>
        <h1 className="content-title" style={{ color: 'white' }}>Vincent Fernandel Production</h1>
      </div>
    </div>
  );
};


export default HeroImg;
