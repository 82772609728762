import React from "react";
import Navbar from "../components/navbar/Navbar";
import HeroImg from "../components/hero/HeroImg";
import Description from "../pages/description";
import TheProduction from "../pages/production/index";
import Artists from "../pages/artists/index";
import Contact from "../pages/contact/index";
import About from "../pages/about";
import Footer from "../components/footer/Footer";
import Banner from "../pages/banner/Banner";
// eslint-disable-next-line
import News from "../pages/news/News";
import "../index.css";
import News2 from "../pages/news2/News2";
import Newsletter from "../pages/newsletter";

const Production = () => {
  return (
    <>
      <Navbar />
      <HeroImg />
      <Description />
      <News2 />
      <Newsletter />
      <TheProduction />
      <Artists />
      <Banner />
      <About />
      <Contact />
      <Footer />
    </>
  );
};

export default Production;
