import React from 'react'
import { motion } from "framer-motion"
import { fadeIn } from "../../../variants"

import './index.css'
import LogoDesc from '../../assets/logo/logoblack.jpg'

const Description = () => {
  return (
    <div>
      <motion.div
        variants={fadeIn("right", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.3 }}
        className='description'>
        <img className='logo-desc' src={LogoDesc} alt='logo-description' />
        <h2 className='description-title'>PRODUCTION & ÉDITION MUSICALE</h2>
        <p className='description-text' style={{ alignContent: 'center' }}>
          VFP accompagne des artistes professionnels à mettre en lumière leur travail, déployer leur potentiel et optimiser leur créativité.
        </p>
      </motion.div>
    </div>

  )
}

export default Description