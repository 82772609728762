import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Mentions = () => {
  return (
    <>
      <Navbar />
      <div className="mentions" id="mentions">
        <h1 className="mentions-title">Mentions Légales</h1>
        <div className="mentions-container">
          <div className="mentions-list">
            <h3 className="mentions-subtitles">Informations générales :</h3>
            <p className="mentions-text">
              Le Site est la propriété de Vincent Fernandel Production, un
              producteur de musique dont le siège social est situé à 115 RUE DE
              L ABBE GROULT 75015 PARIS VINCENT FERNANDEL PRODUCTION.
            </p>
          </div>
          <div className="mentions-list">
            <h3 className="mentions-subtitles">Propriété intellectuelle :</h3>
            <p className="mentions-text">
              Tous les contenus présents sur le Site, tels que les textes, les
              images, les logos, les vidéos, les musiques, les marques et les
              éléments graphiques, sont la propriété exclusive de Vincent
              Fernandel Production ou de ses partenaires, et sont protégés par
              les lois relatives à la propriété intellectuelle. Toute
              reproduction, distribution, modification ou utilisation de ces
              contenus sans autorisation écrite préalable est strictement
              interdite.
            </p>
          </div>
          <div className="mentions-list">
            <h3 className="mentions-subtitles">Utilisation du site :</h3>
            <p className="mentions-text">
              Le Site est destiné à fournir des informations sur les services
              offerts par Vincent Fernandel Production. Nous nous efforçons de
              maintenir les informations du Site à jour et exactes. Cependant,
              nous ne pouvons garantir l'exactitude, l'exhaustivité ou la
              pertinence des informations fournies sur le Site. Vous utilisez
              les informations du Site à vos propres risques.
            </p>
          </div>
          <div className="mentions-list">
            <h3 className="mentions-subtitles">
              {" "}
              Liens vers des sites tiers :
            </h3>
            <p className="mentions-text">
              Le Site peut contenir des liens vers des sites web tiers. Ces
              liens sont fournis uniquement pour votre commodité. Vincent
              Fernandel Production n'exerce aucun contrôle sur le contenu de ces
              sites tiers et n'assume aucune responsabilité quant à leur
              contenu, leur exactitude ou leur fonctionnement. L'inclusion de
              liens vers des sites tiers ne constitue pas une approbation ou une
              recommandation de ces sites par Vincent Fernandel Production.
            </p>
          </div>
          <div className="mentions-list">
            <h3 className="mentions-subtitles">
              {" "}
              Protection des données personnelles :
            </h3>
            <p className="mentions-text">
              Nous attachons une grande importance à la protection de votre vie
              privée. Les informations que vous nous fournissez peuvent être
              collectées et traitées conformément à notre politique de
              confidentialité, disponible sur le Site.
            </p>
          </div>
          <div className="mentions-list">
            <h3 className="mentions-subtitles">
              Limitation de responsabilité :
            </h3>
            <p className="mentions-text">
              Dans la mesure permise par la loi applicable, Vincent Fernandel
              Production décline toute responsabilité quant aux dommages
              directs, indirects, accessoires, consécutifs ou spéciaux résultant
              de l'utilisation ou de l'impossibilité d'utiliser le Site.
            </p>
          </div>
          <div className="mentions-list">
            <h3 className="mentions-subtitles">
              Modifications des mentions légales :{" "}
            </h3>
            <p className="mentions-text">
              Nous nous réservons le droit de modifier ces mentions légales à
              tout moment. Les modifications prendront effet dès leur
              publication sur le Site. Nous vous recommandons de consulter
              régulièrement cette page pour vous tenir informé des éventuelles
              mises à jour. <br />
              Si vous avez des questions concernant ces mentions légales,
              veuillez nous contacter à vincentfernandelproduction@gmail.com.
              Dernière mise à jour : 4 Juin
            </p>
          </div>
        </div>
        <Link to="/" className="back-to-site-btn">
          Retourner à l'accueil
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Mentions;
