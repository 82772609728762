import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../../variants";
import artistsData from "./data";

import "./index.css";

const Artistes = () => {
  return (
    <div className="artistes" id="artistes">
      <h2 className="title">LES ARTISTES</h2>
      <div className="artistes-border"></div>
      <div>
        <ul className="artistes-container">
          {artistsData.map((artist, index) => (
            <motion.li
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.1 }}
              className="artistes-item"
              key={index}
            >
              <h3 className="artistes-title">{artist.name}</h3>
              <img className="artistes-img" src={artist.img} alt="artistes" />
              {artist.linkSpotify && (
                <div className="artistes-link">
                  <a
                    className="artistes-spotify"
                    href={artist.linkSpotify}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Écouter sur Spotify
                  </a>
                </div>
              )}
              {artist.id === 4 && artist.link && (
                <div className="artistes-link">
                  <a
                    className="artistes-spotify"
                    href={artist.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Voir sa discographie
                  </a>
                </div>
              )}
              <h4 className="artistes-subtitle">{artist.title}</h4>
              <div>
                <p className="artistes-text">{artist.description}</p>
                <p className="artistes-text">{artist.description2}</p>
              </div>
            </motion.li>
          ))}
        </ul>
        <div></div>
      </div>
    </div>
  );
};

export default Artistes;
