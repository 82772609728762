
export const Biographie = [
  {
    id: 1,
    para: `
    Issu d'une famille d'artistes, formé aux métiers de l’image, à la direction d’acteur ainsi qu’à l’écriture scénaristique, Vincent Fernandel débute sa carrière dans les médias comme animateur télé et journaliste spécialisé dans le cinéma moderne et patrimonial, en officiant sur des chaînes telles que Match TV, E! Entertainment ou M6. Il signe plus de 400 reportages  sur le 7ème art français, italien et américain. Il réalise également des making-of de films et des documentaires, dont « Canadair, sur la terre comme au ciel » pour France Télévision. Vincent se tourne ensuite vers le spectacle vivant, ce qui le conduit naturellement vers le théâtre. Il entame alors plus de dix années de pédagogie en art dramatique, enseignant l’acting au sein de plusieurs écoles de théâtre avant de fonder les Ateliers Vincent Fernandel en 2017, lieu dédié à la formation pluridisciplinaire de l’acteur. Il devient également metteur en scène ainsi qu'auteur de pièces, parmi lesquelles « Anna attend l’amour », jouée en 2019 au Théâtre des Mathurins à Paris, puis « Mona ». En 2020, Vincent co-signe avec le célèbre journaliste sportif Ian Borthwick, « Au cœur de la fougère » (éditions Au vent des îles), récit d’un road-trip en Nouvelle-Zélande consacré à la mythique équipe de rugby des All Blacks. La même année, il enregistre « Les fables de La Fontaine » (éditions Fleurus) dans une version livre-disque destinée à la jeunesse, à ce jour vendu à plus de 12 000 exemplaires. Viennent ensuite les « Contes classiques », (éd. Fleurus), « Les Lettres de mon moulin » (Wagram / Marianne Mélodie), « Marcel Pagnol lu par Vincent Fernandel » (éd. Fleurus) puis « Jean de Florette » et « Manon des sources », romans phares signés Pagnol, interprétés par Vincent dans leur version intégrale, à paraître début 2024 en livre-audio (Audiolib). `,
    para2: `
    En 2021, Vincent se lance dans la production et l’édition musicale en créant la société Vincent Fernandel Production, dédiée aux artistes de tous horizons. Également auteur de chansons, il devient notamment parolier et producteur du chanteur Florent Richard. Fort d'un parcours éclectique, Vincent Fernandel se consacre principalement à l'accompagnement artistique ainsi qu'à son activité de conteur.`,
    para3: `Découvrez l’actualité artistique de Vincent sur`
  }
]


export default Biographie
