import React from 'react'
import { motion } from "framer-motion"
import { fadeIn } from "../../../variants"
import ProductionData from './data'

import './index.css'


const TheProduction = (props) => {

  return (
    <div className='production' style={{ overflowX: 'hidden' }} id='production'>
      <h2 className='title' style={{ color: 'white', marginBottom: '1rem' }}>VINCENT FERNANDEL PRODUCTION</h2>
      <div className='border'></div>
      <h4 className='production-meet'>Entretien avec Vincent Fernandel</h4>
      <div className='production-container'>
        {ProductionData.map((item, index) => (
          <ul key={index} className="production-list">
            <li className='production-item'>
              <motion.div
                variants={fadeIn("right", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}>
                <h3 className='production-title'>{item.title}</h3>
              </motion.div>
            </li>
            <li>
              <motion.div
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}>
                <h4 className='production-subtitle'>{item.subtitle}</h4>
                <p className='production-text'>{item.text}</p>
              </motion.div>

            </li>
          </ul>
        ))}
      </div>

    </div>
  )
}

export default TheProduction