import React from 'react'
import { Link } from "react-router-dom"

import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-container">
        <p>
          <Link className="footer-link" to='/mentions'>Mentions Légales</Link>
        </p>
        <p>
          <Link className="footer-link" to='/politiquedeconfidentialite'>Politique de confidentialité</Link>
        </p>
        <p>© Copyright 2024 VINCENT FERNANDEL PRODUCTION</p>
        <p>Photos © S.Di Malta / F.Vignal / V.Fernandel / D.R.</p>
      </div>
    </div>
  )
}

export default Footer