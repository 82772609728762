import React from "react";
import { Link } from "react-router-dom";
import "./index.css"; 
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <div className="privacy" id="privacy">
        <h1 className="privacy-title">Politique de Confidentialité</h1>
        <div className="privacy-container">
          <div className="privacy-list">
            <h3 className="privacy-subtitles">Informations générales :</h3>
            <p className="privacy-text">
              Le Site est la propriété de Vincent Fernandel Production, un
              producteur de musique dont le siège social est situé à 115 RUE DE
              L ABBE GROULT 75015 PARIS VINCENT FERNANDEL PRODUCTION.
            </p>
          </div>
          <div className="privacy-list">
            <h3 className="privacy-subtitles">
              Collecte et utilisation des informations :
            </h3>
            <p className="privacy-text">
              Nous collectons certaines informations personnelles lorsque vous
              utilisez notre site web, y compris votre nom, votre adresse
              e-mail, votre adresse postale et vos informations de paiement. Ces
              informations sont utilisées pour traiter vos commandes, vous
              envoyer des communications marketing et améliorer notre site web.
            </p>
          </div>
          <div className="privacy-list">
            <h3 className="privacy-subtitles">Protection des informations :</h3>
            <p className="privacy-text">
              Nous prenons des mesures de sécurité appropriées pour protéger vos
              informations personnelles contre tout accès non autorisé ou toute
              divulgation. Cependant, aucune méthode de transmission sur
              Internet ou de stockage électronique n'est totalement sécurisée,
              et nous ne pouvons garantir la sécurité absolue de vos
              informations.
            </p>
          </div>

          <div className="privacy-list">
            <h3 className="privacy-subtitles">
              Divulgation des informations :
            </h3>
            <p className="privacy-text">
              Nous ne divulguerons pas vos informations personnelles à des tiers
              sans votre consentement, sauf si cela est nécessaire pour fournir
              les services demandés ou si la loi l'exige.
            </p>
          </div>
        </div>
        <Link to="/" className="back-to-site-btn">
          Retourner à l'accueil
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
