import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../../../variants";
import Biographie from "./data";
import BioPic from "../../assets/about/vincent.jpeg";
import "./index.css";

const About = () => {
  return (
    <>
      <div className="about" style={{ overflowX: "hidden" }}>
        <h2 className="title">BIOGRAPHIE</h2>
        <div className="artistes-border"></div>
        <div className="biographie">
          {Biographie.map((item, index) => (
            <div className="biographie-list">
              <p>{item.para}</p>
              <p style={{ marginTop: "20px" }}>{item.para2}</p>
              <p style={{paddingTop: '1rem'}}>
                {item.para3}{" "}
                <a
                  style={{ color: "black", fontWeight: "bold" }}
                  href="https://www.vincent-fernandel.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.vincent-fernandel.com
                </a>
              </p>
            </div>
          ))}
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.1 }}
            className="biographie-right"
          >
            <img className="biographie-pic" src={BioPic} alt="" />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default About;
