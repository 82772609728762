import React, { useRef } from 'react';
import { motion } from "framer-motion"
import { fadeIn } from "../../../variants"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import emailjs from '@emailjs/browser';
import './index.css'

const Contact = () => {

  // Email JS
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_b0qb4h3',
        'template_yjmtaxh',
        form.current,
        'pgp1hpfpOmLlkG7rz'
      )
      .then(
        (result) => {
          console.log(result.text)
          toast.success(
            'Notre équipe vous remercie pour votre message qui sera traité dans les meilleurs délais. Cordialement, Vincent Fernandel Production',
            {
              autoClose: 10000,
            }
          )
          setTimeout(() => {
            document.location.href = '/'
          }, 10000)
        },
        (error) => {
          console.log(error.text)
        }
      )
  }



/*   const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_m2y0seq', 'template_yjmtaxh', form.current, 'pgp1hpfpOmLlkG7rz')
      .then((result) => {
        console.log(result.text);
        console.log('message envoyé')
      }, (error) => {
        console.log(error.text);
      });

    if (sendEmail) {
      document.location.href = '/';
      alert('Notre équipe vous remercie pour votre message, qui sera traité dans les meilleurs délais. Cordialement, Vincent Fernandel Production')
    }
  }; */



  return (
    <div className='contact' id='contact'>
      <div className="form">
        <h2 className='title'>CONTACTER LA PRODUCTION</h2>
        <div className='contact-border'></div>
        <motion.div
         variants={fadeIn("right", 0.2)}
         initial="hidden"
         whileInView={"show"}
         viewport={{ once: false, amount: 0.3 }}

        >
          <form ref={form} onSubmit={sendEmail}>

            {/* Name */}
            <label htmlFor="name">NOM</label>
            <input
              type="name"
              name="user_name"
              placeholder="Vos nom et prénom"
              required />

            {/* Mail */}
            <label htmlFor="mail">ADRESSE MAIL</label>
            <input
              type="email"
              name="user_mail"
              placeholder="votremail@email.com"
              required />

            {/* Phone */}
            <label htmlFor="phone">TELEPHONE</label>
            <input
              type="phone"
              name="user_phone"
              placeholder="+33" />

            {/* Message */}
            <label htmlFor="message">MESSAGE</label>
            <textarea
              name="user_message"
              placeholder="Entrez votre message..."
              required
              minLength={10}
            />

            {/* Submit */}
            <button
              type='submit'
              value='Send'
              className='contact-btn'
            >ENVOYER</button>
          </form>

        </motion.div>


      </div>
      <ToastContainer />

    </div>
  )
}

export default Contact